<template>
<div class="data">
     <slot></slot>
</div>
    
</template>
<script>
export default {
    name:"DataDisplay"
}
</script>
<style scoped>
.data{
    width: 90%;
    margin: 10px auto;
    box-sizing: border-box;
   min-height: 50vh;
    padding: 20px;
  border-radius: 10px;
  box-shadow: -10px -10px 40px #f4f4f4, 10px 10px 40px #bebebe;
}

</style>