<template>
  <div class="home">
    <Header />
    <DataNav />
    <Gsearch />
    <Path />
    <div
      class="frame"
      v-if="
        !getLoading &&
        getDateDateLogs &&
        Object.keys(getDateDateLogs) &&
        Object.keys(getDateDateLogs).length
      "
    >
      <DataDisplay v-for="(d, i) in getDateDateLogs" :key="i">
        <div v-if="!getLoading" class="wrapper">
          <File v-for="data in d" :key="data.name" :ser="i" :data="data" />
        </div>
      </DataDisplay>
    </div>
    <div class="frame" v-else>
      <DataDisplay>
        <div v-if="!getLoading && finalData" class="wrapper">
          <File
            v-for="data in finalData"
            :key="data.name"
            :ser="getServer"
            :data="data"
          />
        </div>

        <div class="loading" v-else>
          <h1>Loading...</h1>
        </div>
      </DataDisplay>
    </div>
  </div>
</template>

<script>
import DataNav from "../components/DataNav.vue";
import Header from "../components/Header.vue";
import Path from "../components/Path.vue";
import DataDisplay from "../components/DataDisplay.vue";
import File from "../components/FolderFile.vue";
import Gsearch from "../components/Gsearch.vue";
import { mapGetters } from "vuex";
export default {
  name: "Home",

  components: {
    Header,
    DataNav,
    Path,
    DataDisplay,
    File,
    Gsearch,
  },
  methods: {
    authCheck() {
      if (!this.getToken.isAuth) {
        this.$router.push({ path: "Auth" });
      }
    },
  },
  computed: {
    ...mapGetters("auth", {
      getToken: "getToken",
    }),
    ...mapGetters("data", {
      getFilteredData: "getFilteredData",
      getSearchKey: "getSearchKey",
      getDashboardData: "getDashboardData",
      getLoading: "getLoading",
      getEndpoint: "getEndpoint",
      getEnv: "getEnv",
      getHub: "getHub",
      getServer: "getServer",
      getDateDateLogs: "getDateDateLogs",
    }),
    finalData() {
      // console.log(this.getDateDateLogs);
      let data;
      if (this.getSearchKey) {
        data = this.getFilteredData;
      } else {
        data = this.getDashboardData;
      }
      return data;
    },
  },
  beforeMount() {
    this.authCheck();
  },
};
</script>
<style scoped>
.home {
  min-height: 100vh;
  width: 100%;
  padding-bottom: 50px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.frame {
  display: flex;
}
</style>