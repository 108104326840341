<template>
  <div class="data">
    <div class="groups">
      <label for="">Search Key-1</label>
      <input
        :disabled="getServer != 'all'"
        :placeholder="getServer == 'all' ? 'key -1' : 'disabled'"
        v-model="k1"
        type="text"
      />
    </div>
    <div class="groups">
      <label for="">Search Key-2</label>
      <input
        :disabled="getServer != 'all'"
        :placeholder="getServer == 'all' ? 'key -2' : 'disabled'"
        v-model="k2"
        type="text"
      />
    </div>
        <div class="groups">
      <label for="">Search Key-3</label>
      <input
        :disabled="getServer != 'all'"
        :placeholder="getServer == 'all' ? 'key -3' : 'disabled'"
        v-model="k3"
        type="text"
      />
    </div>
    <button :disabled="getServer!='all'" @click="getDateDate">{{word}}</button>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Gsearch",
  computed: {
    ...mapGetters("data", {
      getEnv: "getEnv",
      getHub: "getHub",
      getLoading: "getLoading",
      getEndpoint: "getEndpoint",
      getServer: "getServer",
    }),
    k1: {
      get() {
        if (this.getServer == "all"&&this.getEndpoint[0]) {
          return this.getEndpoint[0];
        } else {
          return null;
        }
      },
      set(newV) {
        let s = this.getEndpoint;
        s[0] = newV;
        this.setEndpoint(s);
      },
    },
    k2: {
      get() {
        if (this.getServer == "all"&&this.getEndpoint[1]) {
          return this.getEndpoint[1];
        } else {
          return null;
        }
      },
      set(newV) {
        let s = this.getEndpoint;
        s[1] = newV;
        this.setEndpoint(s);
      },
    },
      k3: {
      get() {
        if (this.getServer == "all"&&this.getEndpoint[2]) {
          return this.getEndpoint[2];
        } else {
          return null;
        }
      },
      set(newV) {
        let s = this.getEndpoint;
        s[2] = newV;
        this.setEndpoint(s);
      },
    },
    word(){
        return this.getServer=="all"?"Search":"disabled"
         
    }
  },
  methods: {
    ...mapMutations("data", {
      setEnv: "SET_ENV",
      setHub: "SET_HUB",
      setServer: "SET_SERVER",
      setEndpoint: "SET_ENDPOINT_EMPTY",
      setSearchKey: "SET_SEARCH_KEY",
      setFilteredData: "SET_FILTERED_DATA",
    }),
    async getDateDate() {
      try {
          if(!this.getEndpoint.length){
            return  alert("please provide key to search")
          }
        let result = await this.$store.dispatch("data/getDateData", {
          env: this.getEnv,
          hub: this.getHub,
          endpoint: this.getEndpoint,
        });
        // console.log(result);
        if (result && result.status == 1001) {
          alert(result.message || "Something went wrong/plz login again");
        } else if (!result || result.status == 1005) {
          this.$store.commit("data/SET_DASHBOARD_DATA", []);
          this.$store.commit("data/SET_ENDPOINT_EMPTY", []);
          this.$store.commit("data/SET_ENV", "");
          this.$store.commit("data/SET_HUB", "");
          this.$store.commit("data/SET_SERVER", "");
          this.$store.dispatch("auth/logout");
          this.$router.push({ path: "Auth" });
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin: 20px auto 5px;
  box-sizing: border-box;
  min-height: 60px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: -10px -10px 40px #f4f4f4, 10px 10px 40px #bebebe;
}
label {
  margin: 5px 25px;
  font-size: 11px;
}
select,
input {
  margin: 0 15px;
  padding: 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #bebebe; */
  width: 200px;
  box-shadow: -3px -3px 15px #f4f4f4, 4px 4px 15px #bebebe;
}
input {
  background-color: #f4f4f8;
}
select option {
  font-size: 17px;
}
.groups {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

button {
  margin: 0 15px;
  padding: 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  width: 100px;
  margin-top: 15px;
}
button:hover,.btn:hover {
  background-color: #2c3e50;
  color: #f4f4f4;
}
</style>