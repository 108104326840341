<template>
  <div class="path">
    <button @click="back()">Back</button>
    <p v-for="ele in getEndpoint" :key="ele">{{ ele }}  /</p>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Path",
  computed: {
    ...mapGetters("data", {
      getDashboardData: "getDashboardData",
      getLoading: "getLoading",
      getEndpoint: "getEndpoint",
      getEnv: "getEnv",
      getHub: "getHub",
      getServer: "getServer",
    }),
  },
  methods: {
    ...mapMutations("data", {
      popEndpoint: "POP_ENDPOINT",
    }),
    async back() {
      try {
        this.popEndpoint();
        let e = "";
        let b=""
        if (this.getEnv === "prd") {
          e = this.getEnv + this.getServer + this.getHub;
        } else {
          e = this.getEnv;
        }
        if(this.getServer=="all"){
          b="data/getDateData"
          e="prd"
        }else{
          b="data/getData"
        }
        let result = await this.$store.dispatch(b, {
          env: e,
          hub: this.getHub,
          endpoint: this.getEndpoint,
        });
        if (result.status == 1001) {
          alert(result.message || "plz login again");
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
        alert("something went wrong");
      }
    },
  },
};
</script>

<style scoped>
.path {
  width: 90%;
  margin: 0 auto;
  margin-top: 25px;
  /* height: 60px; */
  box-sizing: border-box;
  /* padding: 20px; */
  /* border-radius: 10px;
  border: 1px solid #bebebe; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
button{
    margin-right: 15px;
    background-color: transparent;
  padding: 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  width: 100px;
  box-shadow: -3px -3px 15px #f4f4f4, 4px 4px 15px #bebebe;
}
button:hover{
  background-color: #2c3e50;
  color: #f4f4f4;
}
</style>

