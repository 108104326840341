<template>
<div class="wrapper">

  <div class="folder"   v-if="data.type === 'file'">
    <span  class="ic" @click="trigerReq(data)"
      ><i class="far fa-file-code"></i></span>
    <p>{{ data.name }}</p>
  </div>
  <div class="folder" v-else-if="data.type === 'directory'">
    <span class="ic" @click="trigerReq(data)"
      ><i class="far fa-folder"></i></span>
    <p>{{ data.name }}</p>
  </div>
  <div class="folder last-folder" v-else>
  <button @click="copy">Copy</button>
    <p ref="text">{{ data}}</p>
  </div>

</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Folder",
  data() {
    return {};
  },
  props: ["data", "ser"],
  computed: {
    ...mapGetters("data", {
      getEnv: "getEnv",
      getHub: "getHub",
      getLoading: "getLoading",
      getEndpoint: "getEndpoint",
      getServer: "getServer",
    }),
  },
  methods: {
    ...mapMutations("data", {
      setEndpoint: "SET_ENDPOINT",
      setServer: "SET_SERVER"
    }),
    trigerReq(d) {
      this.setEndpoint(d.name);
      this.setServer(this.ser)
       let e=""
      if (this.getEnv === "prd") {
        e= this.getEnv + this.getServer + this.getHub;
      }else{
          e=this.getEnv 
      }
      this.$store.dispatch("data/getData", {
        env: e,
        hub: this.getHub,
        endpoint: this.getEndpoint,
      });
    },
    // to select the content
    selectText(element) {
      var range;
      if (document.selection) {
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    },

    copy() {
      this.selectText(this.$refs.text); 
      document.execCommand("copy");
    },

  },
};
</script>

<style scoped>
.wrapper{
  margin: 20px;


}
.folder {
  text-align: center;
  max-width: 100%;
  box-sizing: border-box;
}
.ic {
  font-size: 50px;
  color: #2c3e50;
}
p {
  color: #2c3e50;
  word-break: break-all;
 
}
.log {
  color: #2c3e50;
}
.data {
  width: 100%;
  min-height: 200px;
}
.content{
  text-align: left;
}
button {
  padding: 5px 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: #fff;
  color: #2c3e50;
  text-align: right;
  
}
button:hover {
  color: #fff;
  background-color: #2c3e50;
}
.last-folder{
  text-align: left;
}
</style>