<template>
  <div class="header">
    <h1>BUG FINDER</h1>
    <div class="right">
      <h3><i class="fas fa-user"></i> {{ uName }}</h3>
      <h5 @click="logout">logout</h5>
    </div>
  </div>
</template>
<script>
// import {mapGetters} from "vuex"
export default {
  name: "Header",
  computed: {
    uName: () => {
      return localStorage.getItem("uName");
    },
  },
  methods: {
    logout() {
      this.$store.commit("data/SET_DASHBOARD_DATA", []);
      this.$store.commit("data/SET_ENDPOINT_EMPTY", []);
      this.$store.commit("data/SET_ENV", "");
      this.$store.commit("data/SET_HUB", "");
      this.$store.commit("data/SET_SERVER", "");
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "Auth" });
    },
  },
};
</script>
<style scoped>
.header {
  height: 100px;
  width: 90%;
  box-sizing: border-box;
  background-color: #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
h5 {
  padding: 10px;
  cursor: pointer;
}
h5:hover {
  color: #fff;
}
</style>